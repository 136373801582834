<template>
  <div class="w-full mt-64">
    <a-loader class="w-14 h-14 block mx-auto" :color="'text-gray'"/>
  </div>
</template>

<script>
export default {
  name: 'external',
  mounted () {
    this.$repository.sololinks.search({ foodie_instagram: this.$route.params.igusername, restaurant_instagram: this.$route.params.restigusername })
      .then(({ data: response }) => {
        this.$repository.sololinks
          .externalBookingVisit(response.id)
          .finally(() => {
            window.location.replace(response.restaurant.settings.bookings_external_link)
            // redirectModal.value = false
          })
      })
      .catch((err) => console.log(err))
  }
}
</script>

<style scoped>

</style>
